<template>
    <b-container class="py-5 text-left contract">
        <h1 class="mb-5 text-center">Contrato de Prestación de Servicio Intercompañías</h1>
        <p>
            El presente contrato de prestación de servicios intercompañías (el “<span
                class="definition"
                >Contrato</span
            >”), se celebra el día 7 (siete) de julio de 2021 (dos mil veintiuno) (la “<span
                class="definition"
                >Fecha Efectiva</span
            >”), entre:
        </p>

        <p>
            <span class="font-weight-bold">(I)</span> Delivering Geniality, S.A.P.I. de C.V.,
            representada por Jorge Arámbula Leaño (el “<span class="definition">Prestador</span>”);
            y
        </p>

        <p></p>
        <p>
            <span class="font-weight-bold">(II)</span> Preparing Champions, S.A. de C.V.,
            representada por Pablo Navarro Leaño (el “<span class="definition">Cliente</span>”, y
            junto con el Prestador como las “<span class="definition">Partes</span>”).
        </p>
        <p>De conformidad con las siguientes declaraciones y cláusulas:</p>

        <h2 class="mt-5 mb-4 text-center">D E C L A R A C I O N E S</h2>
        <p class="font-weight-bold">
            I. Declara el Prestador, por conducto de su representante legal, que:
        </p>
        <ol>
            <li>
                <p>
                    Es una sociedad mercantil legalmente constituida conforme a las leyes de los
                    Estados Unidos Mexicanos, según consta en la póliza 6,371 de fecha 20 de
                    noviembre de 2018, otorgada ante fe del licenciado Gabriel Villaléver García de
                    Quevedo, Corredor Público 65 de la Plaza del Estado de Jalisco, misma que se
                    encuentra debidamente inscrita en el Registro Público del Comercio de
                    Guadalajara, Jalisco bajo folio mercantil electrónico número N-2018096643.
                </p>
            </li>
            <li>
                <p>
                    Su representante cuenta con capacidad y facultades legales suficientes para
                    celebrar el presente Contrato y obligar al Cliente en los términos del mismo, y
                    que dichas facultades no han sido revocadas, modificadas o limitadas en forma
                    alguna.
                </p>
            </li>
            <li>
                <p>
                    Cuenta con personal calificado y especializado, los elementos, recursos y
                    herramientas propias suficientes para prestar los Servicios (según se definen en
                    la Cláusula Primera) que el Cliente le solicita.
                </p>
            </li>
            <li>
                <p>Su objeto social le permite la celebración de este Contrato.</p>
            </li>
            <li>
                <p>
                    Para los efectos de toda notificación, emplazamientos, avisos y/u otros actos
                    judiciales o extrajudiciales que deriven del presente Contrato (las
                    “Notificaciones”), señala como su domicilio convencional el ubicado en avenida
                    Acueducto 6050, UP 38 Sección 2, Plaza Acueducto, Zapopan, Jalisco, código
                    postal 45118.
                </p>
            </li>
            <li>
                <p>
                    Es su voluntad celebrar el presente Contrato con el Cliente, en los términos y
                    condiciones que se establecen más adelante.
                </p>
            </li>
        </ol>
        <p class="font-weight-bold">
            II. Declara el Cliente, por conducto de su representante legal, que:
        </p>
        <ol>
            <li>
                <p>
                    Es una sociedad mercantil legalmente constituida conforme a las leyes de los
                    Estados Unidos Mexicanos, según consta en la escritura pública número 853, de
                    fecha 13 de enero de 2015, otorgada ante fe del licenciado Luis Ricardo
                    Villaseñor Flores, Notario Público 2 de La Barca, Jalisco, misma que se
                    encuentra debidamente inscrita en el Registro Público del Comercio de
                    Guadalajara, Jalisco bajo folio mercantil electrónico 87754*1.
                </p>
            </li>
            <li>
                <p>
                    Su representante cuenta con capacidad y facultades legales suficientes para
                    celebrar el Contrato y obligar al Cliente en los términos del mismo, y que
                    dichas facultades no han sido revocadas, modificadas o limitadas en forma
                    alguna.
                </p>
            </li>
            <li>
                <p>Su objeto social le permite la celebración de este Contrato.</p>
            </li>
            <li>
                <p>
                    Para los efectos de toda Notificación, señala como su domicilio convencional el
                    ubicado en avenida Real Acueducto 4851, piso 17, Zapopan, Jalisco, código postal
                    45116.
                </p>
            </li>
            <li>
                <p>
                    Es su voluntad celebrar el presente Contrato con el Prestador, de conformidad
                    con los términos y condiciones que se establecen más adelante.
                </p>
            </li>
        </ol>
        <p>
            Es su voluntad celebrar el presente Contrato con el Prestador, de conformidad con los
            términos y condiciones que se establecen más adelante.
        </p>
        <h2 class="mt-5 mb-4 text-center">C L Á U S U L A S</h2>
        <p>
            <span class="font-weight-bold">Primera.</span> <span class="definition">Objeto</span>.
            Las Partes acuerdan que la finalidad y objeto del Contrato es regular la prestación por
            parte del Prestador en beneficio del Cliente, de los siguientes servicios que se
            mencionan de forma enunciativa mas no limitativa, a continuación (los “<span
                class="definition"
                >Servicios</span
            >”):
        </p>
        <ol>
            <li>
                <p>
                    Mantenimiento y desarrollo de sistemas de información y software;
                </p>
            </li>
            <li>
                <p>
                    Soporte técnico y gestión de ciertas funciones operativas;
                </p>
            </li>
            <li>
                <p>
                    Otorgamiento de sublicencia de la marca “ZUBUT y Diseños”; y/o
                </p>
            </li>
            <li>
                <p>
                    Cualquier otro servicio relacionado o análogo que solicite el Cliente.
                </p>
            </li>
        </ol>
        <p>
            El Prestador podrá asignar de forma temporal a su personal al Cliente para efectos de la
            prestación de los Servicios (el “<span class="definition">Personal</span>”).
        </p>
        <p>
            El Personal del Prestador deberá acatar y respetar los lineamientos, políticas y reglas
            establecidas por el Cliente cuando por la naturaleza o la necesidad del Servicio deba
            prestar los mismos en el domicilio del Cliente, lo anterior para evitar entorpecer las
            actividades comunes y regulares de este último, actuando siempre y en todo momento en
            beneficio del Cliente.
        </p>
        <p>
            <span class="font-weight-bold">Segunda.</span>
            <span class="definition">Duración de la Prestación de Servicios</span>. Las Partes
            convienen que el Contrato tendrá una duración de 1 año a partir de la Fecha Efectiva,
            cuya duración podrá renovarse de forma automática por periodos iguales y cualquiera de
            las Partes podrá darlo por terminado sin responsabilidad alguna, dando aviso a la otra
            parte con por lo menos 30 días hábiles de anticipación a la fecha efectiva de
            terminación.
        </p>
        <p>
            <span class="font-weight-bold">Tercera.</span>
            <span class="definition">Contraprestación</span>. Las Partes pactan como
            contraprestación mensual a favor del Prestador por los Servicios que prestará al
            Cliente, la cantidad equivalente al costo operativo mensual del Prestador directamente
            relacionado con la prestación de los Servicios, más un porcentaje del 5% sobre el costo
            y gasto de cada Servicio, mismo que podrá ser modificado de tiempo en tiempo por las
            Partes, con base en un reporte detallado que al efecto presente previamente el Prestador
            al Cliente para su aprobación (la “Contraprestación”); para efectos de lo anterior, el
            Cliente contará con un plazo de 15 días para solicitar la aclaración o revisión del
            reporte, por lo que en caso de no solicitar dicha revisión en el plazo señalado, se
            considerará aprobado y será pagadero por el Cliente. La Contraprestación incluye los
            gastos, costos, derechos, y cualquier otra cantidad que se genere por la prestación de
            los Servicios. El Cliente pagará la Contraprestación al Prestador dentro de los primeros
            15 días de cada mes calendario.
        </p>
        <p>
            La Contraprestación será revisada, negociada y actualizada de común acuerdo por escrito
            entre las Partes, en cualquier momento, cuando lo consideren necesario, en el entendido
            que la Contraprestación en términos del presente Contrato podrá variar de mes en mes, ya
            que están relacionados directamente a los costos operativos mensuales del Prestador
            respecto a la prestación de los Servicios.
        </p>
        <p>
            El pago de la Contraprestación se hará por medio de transferencia electrónica a la
            cuenta bancaria que le indique por escrito el Prestador al Cliente por escrito.
        </p>
        <p>
            De igual manera el Prestador proporcionará al Cliente un reporte que describa los gastos
            y costos en los que incurrió a favor del Cliente para la prestación de los Servicios,
            incluyendo aquellos relativos a gastos de viaje (boletos de avión, hoteles,
            transportación terrestre o marítima, estacionamientos, y restaurantes, entre otros).
            Esta información podrá ser proporcionada en formato electrónico. En caso de que el
            Cliente no solicite la aclaración de los gastos o los rechace en los siguientes 3 días
            al envío del reporte de gastos correspondiente, dichos gastos y costos se considerarán
            aprobados y pagaderos por el Cliente.
        </p>
        <p>
            Las Partes acuerdan que el Cliente podrá realizar pagos a favor del Prestador relativos
            a gastos relacionados con la prestación de los Servicios, mismos que el Prestador
            facturará al Cliente y que éste último deberá reembolsar en la forma y plazo que se
            establezca en la factura correspondiente.
        </p>
        <p>
            <span class="font-weight-bold">Cuarta.</span>
            <span class="definition">Obligaciones Adicionales</span>. Las Partes pactan las
            siguientes obligaciones adicionales a cargo del Prestador:
        </p>
        <ol>
            <li>
                <p>
                    El Prestador se compromete y obliga a que todo el Personal cumpla con el código
                    de ética, el comportamiento y los procesos de operación del Cliente.
                </p>
            </li>
            <li>
                <p>
                    En virtud de que el Prestador es y será en todo momento considerado como patrón
                    del Personal, se compromete y garantiza al Cliente que será el único responsable
                    de cumplir con las disposiciones laborales y de seguridad social aplicables a su
                    Personal, y mantendrá a salvo y libre de cualquier responsabilidad al Cliente.
                </p>
            </li>
            <li>
                <p>
                    Cumplir con todas las leyes, disposiciones, normas e impuestos relacionados con
                    el Personal que contrate para la prestación de los Servicios a favor del
                    Cliente.
                </p>
            </li>
            <li>
                <p>
                    Cumplir o causar el cumplimiento de los horarios, normas, roles y
                    responsabilidades que el Cliente designe.
                </p>
            </li>
            <li>
                <p>
                    Cumplir con las obligaciones fiscales aplicables.
                </p>
            </li>
            <li>
                <p>
                    En general, realizar cualquier acto o actividad tendiente al cumplimiento eficaz
                    de la prestación de los Servicios a favor del Cliente.
                </p>
            </li>
        </ol>
        <p>
            <span class="font-weight-bold">Quinta.</span>
            <span class="definition">Terminación Anticipada</span>. Las Partes convienen que el
            Contrato se rescindirá de pleno derecho o se dará por terminado anticipadamente de forma
            inmediata, sin necesidad de declaración judicial, juicio o requerimiento previo, y sin
            responsabilidad alguna para las Partes, cuando se presenten las siguientes
            circunstancias:
        </p>
        <ol>
            <li>
                <p>
                    En caso de que alguna de las Partes falte, parcial o totalmente, al cumplimiento
                    o se retrase en el cumplimiento de cualquiera de las obligaciones a su cargo
                    derivadas del Contrato;
                </p>
            </li>
            <li>
                <p>
                    En los demás supuestos pactados en el resto del clausulado del Contrato; y/o
                </p>
            </li>
            <li>
                <p>
                    Por cualquier supuesto previsto en la ley.
                </p>
            </li>
        </ol>
        <p>
            Inmediatamente después de la terminación del Contrato por cualquier causa, el Prestador
            no tendrá obligación alguna de seguir prestando los Servicios al Cliente, y el Cliente
            deberá pagar al Prestador las cantidades que le adeude en relación con los mismos. En el
            caso de que algunos Servicios se encuentren pendientes de cumplimiento, o la
            Contraprestación pendiente de pago, el Prestador terminará de gestionar el proceso de
            los mismos y el Cliente realizará los pagos pendientes al Prestador, por lo que el
            Contrato terminará en el momento en que la totalidad de los Servicios se hubiesen
            cumplido y la Contraprestación se encuentre totalmente liquidada a entera satisfacción
            de las Partes.
        </p>
        <p>
            <span class="font-weight-bold">Sexta.</span>
            <span class="definition">Relación entre las Partes</span>. El Prestador acepta y está de
            acuerdo en que tanto el Prestador como el Personal que utilice para dar cumplimiento a
            la prestación de los Servicios a favor del Cliente, son y en todo momento permanecerán
            independientes del Cliente, y que la relación entre el Cliente y el Prestador es
            exclusivamente contractual, mercantil, no es permanente, ni crea una relación laboral,
            subordinado o dependiente del Cliente, ya que deriva del Contrato.
        </p>
        <p>
            El Prestador será considerado como patrón, para todos los efectos legales a que haya
            lugar en sus relaciones con las personas que apoyen al Prestador en la prestación de los
            Servicios, por lo que asumirá plena responsabilidad laboral respecto de sí mismo y de
            dichas personas, obligándose a cumplir con todas las disposiciones laborales y de
            seguridad social aplicables vigentes en México.
        </p>
        <p>
            El Prestador conviene en indemnizar por los daños causados y mantener al Cliente libre
            de cualquier responsabilidad en caso de cualquier reclamación, queja, o demanda
            presentada por el Personal del Prestador (o por cualquier persona que le asista en la
            prestación de los Servicios), así como por las autoridades competentes. En virtud de lo
            anterior, las Partes acuerdan que el Cliente no tiene ni tendrá obligación alguna de dar
            de alta al Prestador, ni a su Personal, o cualquier otra persona que le asista en la
            prestación de los Servicios, ante el Instituto Mexicano del Seguro Social o el
            INFONAVIT, ni de darle prestación de carácter laboral y/o de seguridad social alguna.
        </p>
        <p>
            El Prestador será el único responsable de cualquier daño que pudiese sufrir el personal
            del Cliente y/o el Personal del Prestador, en cualquier momento que se encuentren
            realizando alguna actividad relacionada con la prestación de los Servicios, y a
            indemnizar a los mismos conforme a las legislación aplicable en la materia, dependiendo
            el caso, así como a defender y sacar a salvo y en paz al Cliente de cualquier
            obligación, queja, demanda, o reclamación que se hiciere a éste por los daños o pérdidas
            físicas o morales que sufrieren dichas personas durante la prestación de los Servicios.
        </p>
        <p>
            <span class="font-weight-bold">Séptima.</span>
            <span class="definition">Notificaciones</span>. Todas las Notificaciones, demandas,
            consentimientos, requerimientos, aprobaciones, solicitudes u otras comunicaciones que
            cualquiera de las Partes desee o deba realizar conforme al presente Contrato deberán ser
            por escrito y serán entregadas: (a) personalmente con acuse de recibo, o en caso que no
            sea posible, entregadas de forma fehaciente, o (b) por cualquier medio que se deje
            constancia expresa de la recepción de la Notificación, a los domicilios de las Partes
            que se señalan en las Declaraciones del Contrato.
        </p>
        <p>
            <span class="font-weight-bold">Octava.</span>
            <span class="definition">Información Confidencial</span>. Durante el desarrollo del
            Contrato, las Partes podrán proporcionarse cierta información confidencial, por lo que
            deberán conservar toda información confidencial que les sea proporcionada en estricta
            confidencialidad, y deberán abstenerse de divulgar dicha información a toda persona
            física o moral durante la vigencia del Contrato y en cualquier momento posterior a su
            terminación.
        </p>
        <p>
            <span class="font-weight-bold">Novena.</span>
            <span class="definition">Misceláneos</span>. Las Partes acuerdan las siguientes
            obligaciones generales:
        </p>
        <ol>
            <li>
                <p>
                    Modificación. El presente Contrato solamente podrá ser modificado mediante
                    convenio escrito entre las Partes.
                </p>
            </li>
            <li>
                <p>
                    Cesión. Ninguna de las Partes podrá ceder sus derechos y obligaciones derivados
                    del presente Contrato, así como su posición contractual en el mismo, al menos
                    que cuenten con la autorización expresa y por escrito de la otra parte.
                </p>
            </li>
            <li>
                <p>
                    Encabezados. Los títulos o encabezados de las cláusulas de este Contrato se
                    incluyen para facilitar la lectura de las mismas, y no es la intención de las
                    Partes que afecten el significado o la interpretación del presente Contrato.
                </p>
            </li>
            <li>
                <p>
                    Autonomía. En el caso de que una o más de las disposiciones contenidas en este
                    Contrato, o la aplicación de las mismas en cualquier circunstancia, sea
                    declarada como inválida, ilegal, nula o no susceptible de ejecución por
                    cualquier autoridad competente en cualquier aspecto o por cualquier motivo, la
                    validez, legalidad y el ejercicio de cualquiera de dichas disposiciones en
                    cualquier otro aspecto y de las disposiciones restantes de este Contrato, no
                    serán limitadas o afectadas de manera alguna. Adicionalmente, las Partes del
                    presente convienen en utilizar sus mejores esfuerzos para reemplazar dicha
                    disposición que sea inválida, nula, ilegal o no susceptible de ejecución, con
                    una disposición válida, legal y susceptible de ejecución la cual buscará
                    cumplir, al mayor grado posible, los fines económicos, de negocio y otros fines
                    de la disposición inválida, nula o no susceptible de ejecución.
                </p>
            </li>
            <li>
                <p>
                    Acuerdos Previos. El presente Contrato reemplaza todos los acuerdos previos
                    entre las Partes en relación con el objeto del Contrato, y es la intención de
                    las Partes que sea la expresión final y la declaración completa y exclusiva de
                    su acuerdo con respecto al objeto de este Contrato.
                </p>
            </li>
            <li>
                <p>
                    Renuncias. La omisión por cualquiera de las Partes de ejercitar cualquier
                    derecho o de dar por terminado este Contrato por las causas señaladas en el
                    mismo, no deberá ser considerado como una renuncia voluntaria al derecho de
                    hacerlo, sino que por el contrario todas las penas, sanciones y derechos
                    establecidos en este Contrato o por la ley, se considerarán acumulativas, ya sea
                    en el caso de un incumplimiento continuado, o subsecuente de naturaleza similar
                    o de otra forma, o por alguna otra causa.
                </p>
            </li>
            <li>
                <p>
                    Libertad de contratación. Hacen constar las Partes que en forma voluntaria y por
                    así convenir a sus intereses celebran el presente acuerdo de voluntades
                    obligándose a cumplirlo en todos y cada uno de sus términos sin reserva de
                    ningún tipo, confirmando que todos los términos y condiciones han sido fijados
                    en forma justa y equitativa y que los han aceptado libremente. Asimismo, las
                    Partes hacen constar que fueron debidamente asesorados por expertos legales
                    contratados por cada uno de ellos y que estos les explicaron claramente el
                    contenido y consecuencias del presente Contrato.
                </p>
            </li>
            <li>
                <p>
                    Discrepancia entre versiones. El Contrato es realizado en versiones en idioma
                    inglés y español, en caso de discrepancia entre las dos versiones, la versión en
                    idioma español prevalecerá.
                </p>
            </li>
        </ol>
        <p>
            <span class="font-weight-bold">Décima.</span>
            <span class="definition">Jurisdicción</span>. Para el caso de controversia, las Partes
            se someten expresa y voluntariamente a los tribunales competentes del Primer Partido
            Judicial del Estado de Jalisco, y renuncian expresamente a cualquier fuero o
            jurisdicción que les pudiese corresponder por razón de sus domicilios presentes o
            futuros o por cualquier otra causa.
        </p>
        <p></p>
        Enteradas las Partes del contenido y alcance del presente Contrato, lo firman de conformidad
        y por duplicado, en Zapopan Jalisco, en la Fecha Efectiva.
        <div class="mt-4 d-flex justify-content-around">
            <div class="signature text-center">
                <div class="font-weight-bold">
                    El Prestador
                    <hr class="mt-5" />
                    Delivering Geniality, S.A.P.I. de C.V.
                </div>
                <p class="font-weight-regular">Representada por: Jorge Arámbula Leaño</p>
            </div>
            <div class="signature text-center">
                <div class="font-weight-bold">
                    El Cliente
                    <hr class="mt-5" />
                    Preparing Champions, S.A. de C.V.
                </div>
                <p class="font-weight-regular">Representada por: Pablo Navarro Leaño</p>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "Contract",
    metaInfo: {
        title: "Contrato de Prestación de Servicio Intercompañías",
        meta: [
            {
                name: "description",
                content: `Contrato de Prestación de Servicio Intercompañías de Zubut`
            }
        ]
    }
};
</script>

<style scoped lang="scss">
.contract {
    .definition {
        text-decoration: underline;
        font-weight: bold;
        font-style: italic;
    }

    ol {
        list-style-type: lower-alpha;

        li::marker {
            font-weight: bold;
        }
    }
}
</style>
